import * as React from 'react';
import { Content, Header, Layout, SectionTitle } from '../components';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import SiteConfig from '../../config/SiteConfig';

export default class NotFoundPage extends React.Component<any> {
  public render() {
    return (
      <Layout>
        <Helmet title={`404 Not Found  | ${SiteConfig.siteTitle}`} />
        <Header linkToHomepage={true}>
          <SectionTitle>Page not found</SectionTitle>
        </Header>
        <Content>
          <p>The page you requested does not exist; it might have moved. Try browsing <Link to="/">everything</Link> to find what you're
            looking for.</p>
        </Content>
      </Layout>
    );
  }
}
